<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row class="details">
                <b-col lg="12" sm="12">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" class="text-right mb-2">
                            <b-button @click="pdfExport()" variant="success">{{  $t('globalTrans.export_pdf') }}</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="12" sm="12">
                            <b-row>
                                <b-col md=12>
                                    <b-table-simple striped bordered small hover>
                                       <tbody>
                                           <b-tr>
                                                <b-td width="2%">{{ $t('globalTrans.organization') }}</b-td>
                                                <b-td width="6%" colspan="3">{{ (currentLocale == 'bn') ? data.org_name_bn : data.org_name }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{ $t('publication_management.pub_type') }}</b-td>
                                                <b-td>{{ (currentLocale == 'bn') ? data.pub_type_name_bn : data.pub_type_name }}</b-td>
                                                <b-td>{{$t('publication_management.pub_title')}}</b-td>
                                                <b-td>{{ (currentLocale == 'bn') ? data.pub_title_name_bn : data.pub_title_name }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('publication_management.memo_number')}}</b-td>
                                                <b-td>{{ data.circular_auto_id }}</b-td>
                                                <b-td>{{$t('publication_management.circular_subject')}}</b-td>
                                                <b-td>{{ (currentLocale === 'en') ? data.circular_subject : data.circular_subject_bn }}</b-td>
                                            </b-tr>
                                             <!-- <b-tr>
                                                <b-td>{{$t('publication_management.year_en')}}</b-td>
                                                <b-td>{{ $n(data.year_en, { useGrouping: false }) }}</b-td>
                                                <b-td>{{$t('publication_management.month_en')}}</b-td>
                                                <b-td>{{ getMonthName(data.month_en) }}</b-td>
                                            </b-tr> -->
                                             <b-tr>
                                               <b-td>{{$t('publication_management.year_bn')}}</b-td>
                                                <b-td>{{ $n(data.year_bn, { useGrouping: false }) }}</b-td>
                                                <b-td>{{$t('publication_management.month_bn')}}</b-td>
                                                <b-td>{{ getMonthBnName(data.month_bn) }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('publication_management.publication_deadline')}}</b-td>
                                                <b-td>{{ data.deadline_date | dateFormat }}</b-td>
                                                <b-td>{{$t('publication_management.deadline_time')}}</b-td>
                                                <b-td>{{ data.deadline_time | time12Formate}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('publication_management.content_area')}}</b-td>
                                                <b-td colspan="3">{{ (currentLocale === 'en') ? data.content_area : data.content_area_bn }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('publication_management.circular_guideline')}}</b-td>
                                                <b-td colspan="3"><p v-html="(currentLocale === 'en') ? data.circular_subject : data.circular_subject_bn"></p></b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('publication_management.document')}}</b-td>
                                                <b-td colspan="3">
                                                    <div style="float: center;" v-if="data.attachment">
                                                        <a target='_blank' :href="agriResearchServiceBaseUrl + 'storage/publication_management/circular/' + data.attachment">{{data.attachment}} <i class="ri-download-cloud-fill"></i></a>
                                                    </div>
                                                </b-td>
                                            </b-tr>
                                       </tbody>
                                       </b-table-simple>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.close') }}</b-button>
                                            </div>
                                        </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import ExportPdf from './export-pdf_details'
import { agriResearchServiceBaseUrl } from '@/config/api_config'

export default {
    props: ['id'],
    components: {
    },
    created () {
        if (this.id) {
            const tmp = this.getData()
            this.data = tmp
        }
    },
    mounted () {
        core.index()
    },
    data () {
        return {
            data: {},
            agriResearchServiceBaseUrl
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        getData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        getMonthName (monthId) {
            const objectData = this.$store.state.commonObj.monthList.find(type => type.value === monthId)
            if (this.$i18n.locale === 'bn') {
                return objectData.text_bn
            } else {
                return objectData.text_en
            }
        },
        getMonthBnName (monthId) {
            const objectData = this.$store.state.commonObj.banglaMonthList.find(type => type.value === monthId)
            if (this.$i18n.locale === 'bn') {
                return objectData.text_bn
            } else {
                return objectData.text_en
            }
        },
         pdfExport () {
            const reportTitle = this.$t('publication_management.circular') + ' ' + this.$t('globalTrans.details')
            ExportPdf.exportPdfDetails(reportTitle, this, this.data)
        }
    }
}

</script>
<style lang="scss">
    .details {
    h5 {
      color: green !important;
      font-size: 20px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
  }
  .hidden_header {
    display: none
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
