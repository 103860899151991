<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="isLoading">
                    <b-row>
                        <b-col lg="12" sm="12">
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                    <b-row>
                                      <b-col xl="6" lg="6" md="6" sm="12">
                                          <ValidationProvider name="Organization Name" vid="org_id" rules="required|min_value:1">
                                           <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="org_id"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                                {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-select
                                              plain
                                              disabled
                                              v-model="publication.org_id"
                                              :options="organizationProfileList"
                                              id="org_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                                {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                         </ValidationProvider>
                                      </b-col>
                                       <b-col xl="6" lg="6" md="6" sm="12">
                                          <ValidationProvider name="Circular Auto ID" vid="circular_id" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="circular_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('external_research.circular_id')}} <span class="text-danger">*</span>
                                            </template>
                                                <b-form-input
                                                disabled
                                                id="circular_id"
                                                v-model="publication.circular_auto_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                          </ValidationProvider>
                                      </b-col>
                                      <b-col xl="6" lg="6" md="6" sm="12">
                                         <ValidationProvider name="Publication Type" vid="pub_type_id" rules="required|min_value:1">
                                           <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="pub_type_id"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                                {{$t('publication_management.pub_type')}} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-select
                                              disabled
                                              plain
                                              v-model="publication.pub_type_id"
                                              :options="publicationTypeList"
                                              id="pub_type_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                                {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                         </ValidationProvider>
                                      </b-col>
                                      <b-col xl="6" lg="6" md="6" sm="12">
                                        <ValidationProvider name="Publication Title" vid="pub_title_id" rules="required|min_value:1">
                                            <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="pub_title_id"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                              {{$t('publication_management.pub_title')}} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-select
                                              plain
                                              disabled
                                              v-model="publication.pub_title_id"
                                              :options="publicationTitleList"
                                              id="pub_title_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                       <b-col xl="6" lg="6" md="6" sm="12">
                                        <ValidationProvider name="Content Area" vid="pub_title_id" rules="required|min_value:1">
                                            <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="pub_title_id"
                                              slot-scope="{ valid, errors }"
                                              >
                                              <template v-slot:label>
                                              {{$t('publication_management.content_area')}} <span class="text-danger">*</span>
                                              </template>
                                              <b-form-select
                                              plain
                                              v-model="publication.content_area_id"
                                              :options="publicationContentAreaList"
                                              id="pub_title_id"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              <template v-slot:first>
                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                              </template>
                                              </b-form-select>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col xl="6" lg="6" md="6" sm="12">
                                        <ValidationProvider name="Publication Title Name" vid="title_name" rules="required">
                                          <b-form-group
                                              class="row"
                                              label-cols-sm="4"
                                              label-for="title_name"
                                              slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                              {{$t('external_research.content_title')}} <span class="text-danger">*</span>
                                          </template>
                                              <b-form-input
                                              id="title_name"
                                              v-model="publication.content_title"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              ></b-form-input>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-col xl="12" lg="12" md="12" sm="12">
                                        <ValidationProvider name="Publication Content" vid="content_en" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="2"
                                                label-for="content_en"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('external_research.pub_content')}} <span class="text-danger">*</span>
                                            </template>
                                                <vue-editor
                                                  id="content_en"
                                                  v-model="publication.content"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </vue-editor>
                                                <div class="invalid-feedback d-block">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </b-col>
                                    </b-row>
                                    <b-row class="mt-2">
                                      <b-col lg="12" md="12" sm="12">
                                          <p class="text-black" style="border-bottom: 1px solid #ddd; font-size: 15px; font-weight: bold">
                                              {{ $t('external_research.documents') }}
                                          </p>
                                      </b-col>
                                      <b-col lg="6" v-for="(document, index) in publication.documents" :key="index">
                                          <b-row>
                                            <b-col sm="12" lg="9">
                                                <ValidationProvider name="Upload" :vid="`attachment${index}`" :rules="isAdd?'required':''">
                                                      <b-form-group
                                                          class="row"
                                                          label-cols-sm="5"
                                                          :label-for="`attachment${index}`"
                                                          slot-scope="{ valid, errors }"
                                                      >
                                                          <template v-slot:label>
                                                              {{ $t('external_research.document_upload')}} <span v-if="isAdd" class="text-danger">*</span>
                                                          </template>
                                                          <b-form-file
                                                              :id="`attachment${index}`"
                                                              v-model="document.attachment"
                                                              v-on:change="onChange($event, index)"
                                                              :state="errors[0] ? false : (valid ? true : null)"
                                                          >
                                                          </b-form-file>
                                                          <div class="invalid-feedback">
                                                              {{ errors[0] }}
                                                          </div>
                                                          <div class="invalid-feedback d-block">
                                                              {{ 'Document must be a file of type: pdf, xls, xlsx, doc, docx, csv, jpg, jpeg.)' }}
                                                          </div>
                                                      </b-form-group>
                                                </ValidationProvider>
                                              </b-col>
                                              <b-col sm="12" lg="3">
                                                  <span class="btn btn-primary btn-sm mr-2" :title="$t('grant_config.add_more')" v-if="showAdd(index)" @click="addDocument" size="sm"><i class="ri-add-line m-0"></i></span>
                                                  <span class="btn btn-danger btn-sm" :title="$t('globalTrans.delete')" v-if="showTrash(index)" @click="deleteDocument(index)" size="sm"><i class="ri-delete-bin-line m-0"></i></span>
                                              </b-col>
                                            </b-row>
                                        </b-col>
                                      </b-row>
                                      <b-row class="mt-2">
                                        <b-col lg="12" md="12" sm="12">
                                            <p class="text-black" style="border-bottom: 1px solid #ddd; font-size: 15px; font-weight: bold">
                                                {{ $t('publication_management.writer_information') }}
                                            </p>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                            <ValidationProvider name="Name" vid="name" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="name"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('globalTrans.name')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    disabled
                                                    id="name"
                                                    v-model="profile.name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xl="6" lg="6" sm="12">
                                            <ValidationProvider name="Mobile No" vid="mobile_no" rules="required|numeric|min:11|max:11">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="mobile_no"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('globalUserData.mobile_no')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    disabled
                                                    id="mobile_no"
                                                    v-model="profile.mobile_no"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xl="6" lg="6" sm="12">
                                            <ValidationProvider name="Email" vid="email" rules="required|email">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="email"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('globalTrans.email')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    disabled
                                                    id="email"
                                                    v-model="profile.email"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xl="6" lg="6" sm="12">
                                            <ValidationProvider name="NID No" vid="nid" rules="required|numeric">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="nid"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('globalTrans.nid')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    disabled
                                                    id="nid"
                                                    v-model="profile.nid"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xl="6" lg="6" sm="12">
                                            <ValidationProvider name="Gender" vid="gender" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="gender"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('globalUserData.gender')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    disabled
                                                    id="gender"
                                                    v-model="profile.gender"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xl="6" lg="6" sm="12">
                                            <ValidationProvider name="Occupation" vid="occupation" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="occupation"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('globalTrans.occupation')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    disabled
                                                    id="occupation"
                                                    v-model="profile.occupation"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col xl="6" lg="6" sm="12">
                                            <ValidationProvider name="Address" vid="address" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="address"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('globalTrans.address')}} <span class="text-danger">*</span>
                                                </template>
                                                    <b-form-input
                                                    disabled
                                                    id="address"
                                                    v-model="profile.address"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                      </b-row>
                                      <hr>
                                      <div class="row">
                                        <div class="col-sm-3"></div>
                                          <div class="col text-right">
                                                <b-button type="submit" :disabled="isLoading" variant="warning" class="mr-1">{{ $t('globalTrans.draft') }}</b-button>
                                                &nbsp;
                                                <b-button type="submit" :disabled="isLoading" @click="publication.app_status = 1" variant="primary" class="mr-1">{{ saveBtnName }}</b-button>
                                                &nbsp;
                                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                          </div>
                                      </div>
                                </b-form>
                            </ValidationObserver>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { publicationProposalStoreApi, profileListData } from '../../api/routes'
import { VueEditor } from 'vue2-editor'
import { mapGetters } from 'vuex'
export default {
  name: 'FormLayout',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider,
    VueEditor
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.submit') : this.$t('globalTrans.save'),
      isLoading: false,
      isAdd: true,
      publication: {
        app_status: 0,
        circular_id: 0,
        org_id: 0,
        pub_type_id: 0,
        pub_title_id: 0,
        content_area_id: 0,
        circular_auto_id: '',
        content_title: '',
        content: '',
        documents: [
          { attachment: [] }
        ]
      },
      profile: {
        name: '',
        gender: '',
        occupation: '',
        email: '',
        mobile_no: '',
        nid: '',
        address: ''
      },
      publicationTitleList: [],
      publicationContentAreaList: []
    }
  },
  computed: {
    organizationProfileList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
    },
    publicationTypeList: function () {
        return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationTypeList.filter(type => type.status === 1).map(obj => {
          if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    // publicationContentAreaList: function () {
    //     return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationContentAreaList.filter(type => type.status === 1).map(obj => {
    //       if (this.$i18n.locale === 'bn') {
    //             return { value: obj.value, text: obj.text_bn }
    //         } else {
    //             return { value: obj.value, text: obj.text_en }
    //         }
    //     })
    // },
    currentLocale () {
        return this.$i18n.locale
    },
    ...mapGetters({
        authUser: 'Auth/authUser'
    })
  },
  created () {
    if (this.id) {
      const tmp = this.getData()
      this.publication.circular_id = this.id
      this.publication.org_id = tmp.org_id
      this.publication.pub_type_id = tmp.pub_type_id
      this.publication.pub_title_id = tmp.pub_title_id
      this.loadContentAreaList(tmp.content_area_ids)

      this.publication.circular_auto_id = tmp.circular_auto_id
    }
    if (this.authUser.user_id) {
      this.loadAuthProfileData()
    }
  },
  mounted () {
    core.index()
  },
   watch: {
    'publication.pub_type_id': function (newVal) {
      this.publicationTitleList = this.getPublicationTitleList(newVal)
    }
  },
  methods: {
    loadContentAreaList (Ids) {
        JSON.parse(Ids).forEach((element) => {
            return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationContentAreaList.filter(type => type.value === parseInt(element)).map(obj => {
                const item = { value: obj.value, text: this.$i18n.locale === 'bn' ? obj.text_bn : obj.text_en }
                this.publicationContentAreaList.push(item)
            })
        })
    },
    deleteDocument (i) {
        this.publication.documents = this.publication.documents.filter((_, index) => index !== i)
    },
    showTrash (index) {
        const length = this.publication.documents.length
        if (length === 1) {
            return false
        }
        return true
    },
    onChange (event, index) {
        const input = event.target
        if (input.files && input.files[0]) {
            const format = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpg', 'jpeg']
            const ext = input.files[0].name.slice((input.files[0].name.lastIndexOf('.') - 1 >>> 0) + 2)
            const isSupport = format.find(el => el === ext)
            if (typeof isSupport !== 'undefined') {
                const reader = new FileReader()
                reader.onload = (e) => {
                    this.publication.documents[index].file = e.target.result
                }
                reader.readAsDataURL(input.files[0])
            } else {
                this.$toast.error({
                    title: 'Invalid File!',
                     message: 'Only pdf, doc, docx, xls, xlsx, jpg and jpeg files are allowed',
                    color: '#D6E09B'
                })
                this.publication.documents[index].file = ''
                this.publication.documents[index].attachment = []
            }
        } else {
            this.publication.documents[index].file = ''
        }
    },
    showAdd (index) {
        const length = this.publication.documents.length
        if (index === length - 1) {
            return true
        }
        return false
    },
    addDocument () {
        const obj = { attachment: [] }
        this.publication.documents.push(obj)
    },
    getPublicationTitleList (Id) {
        return this.$store.state.ExternalAgriResearch.agriResearchCommonObj.publicationTitleList.filter(item => item.status === 1 && item.pub_type_id === Id).map(obj => {
          if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    getData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    loadAuthProfileData () {
        this.isLoading = true
        RestApi.getData(agriResearchServiceBaseUrl, profileListData, { user_id: this.authUser.user_id }).then(response => {
            if (response.success) {
                const data = response.data
                this.profile.name = this.$i18n.locale === 'bn' ? data.name_bn : data.name
                this.profile.gender = this.getGenderName(data.gender)
                this.profile.nid = data.nid
                this.profile.email = data.email
                this.profile.mobile_no = data.mobile_no
                this.profile.occupation = this.$i18n.locale === 'bn' ? data.occupation_bn : data.occupation
                this.profile.address = this.$i18n.locale === 'bn' ? data.address_bn : data.address_en
                this.isLoading = false
            } else {
                this.profile = {}
                this.isLoading = false
            }
        })
    },
    getGenderName (Id) {
        const Obj = this.$store.state.ExternalUserIrrigation.genderList.find(newItem => newItem.value === Id)
        if (this.$i18n.locale === 'bn') {
            return Obj.text_bn
        } else {
            return Obj.text_en
        }
    },
    async saveData () {
      this.isLoading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      // if (this.id) {
      //   result = await RestApi.putData(agriResearchServiceBaseUrl, `${publicationTitleUpdateApi}/${this.id}`, this.publication)
      // } else {
        result = await RestApi.postData(agriResearchServiceBaseUrl, publicationProposalStoreApi, this.publication)
      // }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
            this.$store.dispatch('ExternalAgriResearch/mutateAgriResearchExternalDropdown', { hasDropdownLoaded: false })
            this.$toast.success({
                title: 'Success',
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
            })
            this.isLoading = false
            this.$router.push({ path: '/research-farmer/publication-management/content-submission-list' })
            this.$bvModal.hide('modal-4')
      } else {
            this.isLoading = false
            this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
